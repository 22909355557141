import { useCallback, useContext, useEffect, useState } from "react";
import BaseModel from "base/BaseModel";
import useWindowSize from "hooks/windowSizeHook";
import APIHelper from "base/APIHelper";
import { APIRequestGenerator } from "base/BaseActions";
import GeneralModel from "./generalModel";
import UserModel from "./userModel";

const useAppUIModel = (savedModelInstance = {}) => {
  // Region External Hooks

  const { width: windowWidth } = useWindowSize();
  const { errorHandler } = useContext(GeneralModel.Context);
  const { token, Organization } = useContext(UserModel.Context);

  // End Region

  // Region State, Memos, Refs

  const [menuWidth] = useState(300);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showMenu, setShowMenu] = useState(!(windowWidth <= 980));
  const [logo, setLogo] = useState(null);

  // End Region

  // Region Functions and Callbacks
  async function fetchData() {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/Configuration`,
      {
        Organization,
        _populate: "logo",
      },
      token,
    );
    try {
      const { records } = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers,
      );

      setLogo(records[0]?.logo);
    } catch (error) {
      errorHandler(error);
    }
  }

  const toggleMenu = useCallback((state) => {
    setShowMenu(state);
  }, []);

  // End Region

  // Region useEffect

  useEffect(() => {
    fetchData();
  }, []);

  // End Region

  return {
    windowWidth,
    menuWidth,
    headerHeight,
    setHeaderHeight,
    showMenu,
    setShowMenu,
    toggleMenu,
    logo,
  };
};

const AppUIModel = BaseModel(useAppUIModel, "AppUIModel");

export { useAppUIModel };
export default AppUIModel;
