export const UNKNOWN = "unknown";
export const CMS_ADMIN = "cmsAdmin";
export const SITE_ADMIN = "siteAdmin";
export const MANAGER = "manager";
export const SAFETY_ADMIN = "safetyAdmin";
export const WORKER = "worker";
export const FRONT_LINE = "frontline";
export const GUEST = "guest";

export const QueryTypes = {
  PAGE: "page",
  SORT: "sort",
  FILTER: "filter",
  TAB: "tab",
};

export const POINT_ITEM_TYPES = ["safetyPoint", "rewardPoint"];

export const POINT_ITEM_ACTIONS = ["credit", "debit"];

export const PENDING = "pending";
export const PENDING_APPROVAL = "pendingApproval";
export const ACTIVE = "active";
export const SUSPENDED = "suspended";
export const COMPLETED = "completed";
export const REJECTED = "rejected";
export const APPROVED = "approved";

export const SUPPORTED_LANGUAGE = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "zh",
    label: "繁體中文",
  },
  {
    value: "zhCHS",
    label: "简体中文",
  },
];

export const GENDER = [
  {
    label: "Female",
    value: "f",
  },
  {
    label: "Male",
    value: "m",
  },
];

export const TAG_TYPES = [
  { type: "constructionSite", refType: "ConstructionSite" },
];

export const ACCEPTED_SRC_TYPES = {
  SUBCONTRACTOR: "SubContractor",
  POINT_ITEM: "PointItem",
};

export const REF_TYPES = {
  CONSTRUCTION_SITE: "ConstructionSite",
};

export const CATEGORY = {
  COURSE: "course",
  TRAINING: "training",
};

export const STOCK_STATUS = {
  IN_STOCK: "inStock",
  LOW_STOCK: "lowStock",
  OUT_OF_STOCK: "outOfStock",
};
