import styled from "styled-components";
import { animated } from "@react-spring/web";

const MenuButtonContainer = styled.div`
  background-color: #f8f8f8;
  display: flex;
  padding: 10px 8px;
  position: fixed;
  width: 10px;
  height: 25px;
  align-items: center;
  border-radius: 0 10px 10px 0;
  transform: translateX(
    ${({ $showMenu, $menuWidth }) => ($showMenu ? `${$menuWidth}px` : 0)}
  );
  transition: all ease-in-out 300ms;
  justify-content: center;
  z-index: 1000;
  top: calc(50% - 25px);
  svg {
    height: 15px;
    width: 15px;
    fill: ${({ theme }) => theme.primary};
  }
`;

const MenuButton = styled.button``;

const OverLayer = styled.div`
  display: none;

  @media (max-width: 980px) {
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    visibility: ${({ $showMenu }) => ($showMenu ? "visible" : "hidden")};
    z-index: ${({ $showMenu }) => ($showMenu ? 1 : 0)};
    opacity: ${({ $showMenu }) => ($showMenu ? 1 : 0)};
  }
`;

const MenuFooter = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  bottom: 0;
  height: 100px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f8f8f8;
  height: 100vh;
  position: fixed;
  box-shadow: ${({ theme }) => theme.boxShadow};
  z-index: ${({ $showMenu }) => ($showMenu ? 999 : 0)};
  transition: opacity 300ms, visibility 300ms, width 300ms;
  visibility: ${({ $showMenu }) => ($showMenu ? "visible" : "hidden")};
  width: ${({ $showMenu, $menuWidth }) =>
    $showMenu ? `${$menuWidth}px` : "0px"};
  box-sizing: border-box;
  opacity: ${({ $showMenu }) => ($showMenu ? 1 : 0)};

  ${MenuFooter} {
    width: ${({ $showMenu, $menuWidth }) =>
      $showMenu ? `${$menuWidth - 40}px` : "0px"};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  border-bottom: solid thin #e3f2ff;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  border-radius: 20px;
`;

const LogOutButton = styled.button`
  color: ${({ theme }) => theme.primary};
  font-size: 14px;
  text-decoration: underline;
  white-space: nowrap;
`;

const Divider = styled.div`
  border-bottom: ${({ theme }) => `2px solid ${theme.border}`};
  width: 100%;
  align-self: center;
  margin: 10px 0;
`;

const BodyContainer = styled.div`
  width: 100%;
  max-height: calc(100% - 220px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledMenuItem = styled(animated.div)`
  background-color: ${({ theme, $focus, $isParent }) =>
    $focus && !$isParent ? theme.primary : theme.white};
  border-radius: 10px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  overflow: hidden;
  transition: background-color 100ms;
`;

const StyledParentItem = styled.button`
  width: 100%;
  display: flex;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
  height: ${({ height }) => `${height}px`};

  svg {
    height: 20px;
    width: 20px;
    path {
      stroke: ${({ theme, $focus, $isParent, $expand }) => {
        if (($isParent && $focus) || $expand) {
          return theme.primary;
        }
        if ($focus) {
          return theme.white;
        }
        return theme.gray;
      }}
    }
    &:last-child{
      height: 6px;
      width: 11px;
      transition: all 300ms;
      transform: ${({ $expand }) =>
        $expand ? "rotateZ(180deg)" : "rotateZ(0deg)"};
    }
  }
}
  
`;

const StyledSubContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px;
  box-sizing: border-box;
`;

const StyledSubItem = styled.button`
  width: 100%;
  display: flex;
  padding: 10px 30px;
  align-items: center;
  justify-content: space-between;
  transition: background-color 300ms;
  background-color: ${({ theme, $focus }) =>
    $focus ? theme.primary : theme.white};
  border-radius: 10px;
  height: ${({ height }) => `${height}px`};
`;

const LogoImg = styled.img`
  height: 50px;
`;

export {
  MenuButtonContainer,
  MenuButton,
  OverLayer,
  Wrapper,
  HeaderContainer,
  LogOutButton,
  Divider,
  BodyContainer,
  StyledMenuContainer,
  StyledMenuItem,
  StyledParentItem,
  StyledSubContainer,
  StyledSubItem,
  MenuFooter,
  LogoImg,
};
