import React, { useContext } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AppUIModel from "models/appUIModel";
import Logo from "assets/images/buildking_logo.png";
import { StyledP } from "base/BaseStyle";
import moment from "moment/moment";
import {
  Divider,
  LogoImg,
  MenuButton,
  MenuButtonContainer,
  MenuFooter,
  OverLayer,
  Wrapper,
} from "./SlideMenu.styles";
import MenuHeader from "./containers/MenuHeader";
import MenuBody from "./containers/MenuBody";

export default function SlideMenu() {
  const { menuWidth, showMenu, toggleMenu, logo } = useContext(
    AppUIModel.Context,
  );
  return (
    <aside>
      <MenuButtonContainer $menuWidth={menuWidth} $showMenu={showMenu}>
        <MenuButton onClick={() => toggleMenu(!showMenu)}>
          {showMenu ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
        </MenuButton>
      </MenuButtonContainer>
      <OverLayer $showMenu={showMenu} />
      <Wrapper $showMenu={showMenu} $menuWidth={menuWidth}>
        <MenuHeader />
        <Divider />
        <MenuBody />
        <MenuFooter>
          <LogoImg src={logo?.fileUrl || Logo} />
          <StyledP fontSize="9" color="text">
            {`© ${moment().year()} Build King Management Limited. All rights reserved.`}
          </StyledP>
        </MenuFooter>
      </Wrapper>
    </aside>
  );
}
