import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "constructionSite";

const useConstructionSiteModel = (savedModelInstance = {}) => {
  const { token, Organization } = useContext(UserModel.Context);

  const { getList, getOne, getSchema, createOne, updateOne, deleteOne } =
    BaseActions(module, { token, Organization });

  async function getProducts(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/getProducts`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        {
          queryParams: params,
        },
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createProduct(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/createProduct`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateProduct(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/updateProduct`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers,
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateProductStock(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/updateProductStock`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers,
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getOrders(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/getOrders`,
      null,
      token,
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {
          queryParams: params,
        },
        true,
        headers,
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    getSchema,
    createOne,
    updateOne,
    deleteOne,
    getProducts,
    createProduct,
    updateProduct,
    updateProductStock,
    getOrders,
  };
};

const ConstructionSiteModel = BaseModel(
  useConstructionSiteModel,
  "ConstructionSiteModel",
);

export { useConstructionSiteModel };
export default ConstructionSiteModel;
