import customCaseTable from "./CustomCaseTable";
import moduleTable from "./ModuleTable";

const unKnowError = {
  en: "Unknown Error. Please try again.",
  zh: "發生未知錯誤，請稍後再試",
};

function errorTextHelper(responseBody) {
  const { errorCode = "", data = {}, description = "" } = responseBody || {};
  const { ajvError, path = "" } = data || {};

  if (!errorCode) {
    return unKnowError;
  }

  const [module, errorType, customCase] = errorCode.split("-") || [];
  const moduleText = moduleTable(module);
  const customText = customCaseTable(customCase);

  // Custom Case
  const last3String = customCase ? customCase.replace(/[a-zA-Z]/g, "") : "";
  if (last3String !== "000" && customText) {
    return customText;
  }

  // Authorization
  if (errorType === "A") {
    return "Authorization Error.Please refresh the page and login again.";
  }

  // Unknown / Uncaught Error / Unclassified
  if (errorType === "U") {
    return description || unKnowError;
  }

  // Not Found
  if (errorType === "N") {
    return {
      en: `Record(s) not found : ${moduleText}`,
      zh: `沒有紀錄 : ${moduleText}`,
    };
  }

  // Payment
  if (errorType === "P") {
    return "Payment Failed!";
  }

  // Forbidden / Permission
  if (errorType === "F") {
    return {
      en: `Permission Denied : ${moduleText}`,
      zh: `沒有權限 : ${moduleText}`,
    };
  }

  // Validation
  if (errorType === "V") {
    if (ajvError) {
      const array = ajvError.map((e, i) => {
        const { message } = e || {};

        return `${i + 1}. ${message}\n`;
      });

      return array.toString().replace(/,/g, "");
    }

    if (path) {
      return `Invalid data or Missing required data,\nPath: ${path}`;
    }

    return unKnowError;
  }

  // Business logic
  if (errorType === "B") {
    return description || unKnowError;
  }

  // E-Shop Service
  if (errorType === "E") {
    if (description?.includes("E11000 duplicate key error collection")) {
      return "Product: Duplicated Product Code";
    }
    return description || unKnowError;
  }

  return unKnowError;
}

function errorResponseGenerator(description, remarks = {}) {
  const stringOfRemarks = Object.entries(remarks).reduce(
    (acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      return acc.concat("", `${key} : ${value}\n`);
    },
    "",
  );

  return {
    description,
    errorCode: "frontEndError",
    remarks: stringOfRemarks,
    title: "Error (CMS)",
  };
}

export { errorTextHelper, errorResponseGenerator };
