import UserModel from "./userModel";
import AppUIModel from "./appUIModel";
import GeneralModel from "./generalModel";
import PopUpModel from "./popUpModel";
import RiskReportModel from "./riskReportModel";
import UserGroupModel from "./userGroupModel";
import ZoneModel from "./zoneModel";
import CertificateModel from "./certificateModel";
import CourseModel from "./courseModel";
import PointItemModel from "./pointItemModel";
import PointCategoryModel from "./pointCategoryModel";
import WalletModel from "./walletModel";
import DocumentModel from "./documentModel";
import ReportModel from "./reportModel";
import ConstructionSiteModel from "./constructionSiteModel";
import SubContractorModel from "./subContractorModel";
import ProfileModel from "./profileModel";
import ConfigurationModel from "./configurationModel";
import PointTransactionModel from "./pointTransactionModel";
import TagModel from "./tagModel";
import TagTypeModel from "./tagTypeModel";
import ServiceEndpointModel from "./serviceEndpointModel";

const Models = [
  // Add new Models here
  ServiceEndpointModel,
  TagTypeModel,
  TagModel,
  PointTransactionModel,
  ConfigurationModel,
  ProfileModel,
  SubContractorModel,
  ConstructionSiteModel,
  ReportModel,
  DocumentModel,
  WalletModel,
  PointCategoryModel,
  PointItemModel,
  CourseModel,
  CertificateModel,
  ZoneModel,
  UserGroupModel,
  RiskReportModel,
  AppUIModel,
  UserModel,
  GeneralModel,
  PopUpModel,
  // Don't add new Model here
];

const BindModels = (ViewComponent) => {
  let bindedComponent = ViewComponent;
  Models.forEach((model) => {
    bindedComponent = model.withProvider(bindedComponent);
  });
  return bindedComponent;
};

export { BindModels };
export default Models;
