import React from "react";
import { useTranslation } from "react-i18next";
import { StyledP } from "base/BaseStyle";
import { TagContainer } from "./StatusTag.styles";

function StatusTag({ status }) {
  const { t } = useTranslation();
  const i18nStatus = t(`Status.${status}`);
  if (!status) {
    return <StyledP>-</StyledP>;
  }

  switch (status) {
    case "confirmed": {
      return <TagContainer $bgColor="primary">{i18nStatus}</TagContainer>;
    }
    case "inStock":
    case "delivered":
    case "approved":
    case "active":
    case "success":
    case "completed": {
      return <TagContainer $bgColor="success">{i18nStatus}</TagContainer>;
    }
    case "outOfStock":
    case "cancelled":
    case "rejected":
    case "inactive":
    case "suspended": {
      return <TagContainer $bgColor="danger">{i18nStatus}</TagContainer>;
    }
    case "lowStock":
    case "submitted":
    case "pending":
    case "pendingApproval": {
      return <TagContainer $bgColor="gray">{i18nStatus}</TagContainer>;
    }
    default: {
      return <span>{i18nStatus}</span>;
    }
  }
}

export default StatusTag;
